import { FC, useMemo } from 'react';

import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { useFormContext } from 'react-hook-form-mui';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconImg from 'src/assets/images/icons/receipt-landing.svg';

import { ServiceProviderDetailsDto } from 'src/generated/services/TFinancialApi';
import { LandingSection } from './Section';
import { MultipleSelectDropdown } from '../analytics/filter/MultipleSelectDropdown';
import { IAuditProps, IServiceList } from './types';
import { LandingAuditProviders } from './AuditProviders';

const StyledBox = styled(Box)({
  '& .MuiFormControl-root': {
    width: '180px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

const sortOptions = [
  {
    label: 'Action Needed',
    value: 'ACTION_NEEDED',
  },
  {
    label: 'Date (Newest to Oldest)',
    value: 'DATE_DESC',
  },
  {
    label: 'Date (Oldest to Newest)',
    value: 'DATE_ASC',
  },
];

const getActionNeededTotals = (item: ServiceProviderDetailsDto) => {
  let actionTotal = 0;
  item?.invoiceStatuses?.forEach((status) => {
    actionTotal += status?.count || 0;
  });

  return actionTotal;
};

export const LandingAudit: FC<IAuditProps> = ({ providers, isLoading }) => {
  const { watch, control } = useFormContext();
  const sort = watch('auditSort');

  const listProviders = useMemo(() => {
    const groupedItems: IServiceList = {
      AIR: [],
      ROAD: [],
      SEA: [],
      CT: [],
      COURIER: [],
    };
    if (providers) {
      const newItems = [...providers];
      newItems.sort((a, b) => {
        let compareStatus = 0;

        if (sort === 'DATE_DESC' || sort === 'DATE_ASC') {
          const aDate = new Date(a.latestInvoiceCreatedDate);
          const bDate = new Date(b.latestInvoiceCreatedDate);
          const compareDateStatus = sort === 'DATE_DESC' ? isBefore(aDate, bDate) : isAfter(aDate, bDate);
          compareStatus = compareDateStatus ? 1 : -1;
        } else if (sort === 'SERVICE_PROVIDER') {
          const aProvider = a.serviceProviderCode;
          const bProvider = b.serviceProviderCode;
          compareStatus = `${aProvider}`.localeCompare(bProvider);
        } else if (sort === 'MODALITY') {
          const aModality = a.modality;
          const bModality = b.modality;
          compareStatus = `${aModality}`.localeCompare(bModality);
        } else {
          const aTotal = getActionNeededTotals(a);
          const bTotal = getActionNeededTotals(b);
          compareStatus = bTotal - aTotal;
        }

        return compareStatus;
      });
      newItems.forEach((item) => {
        const modality = item?.modality;

        if (typeof groupedItems[modality] !== 'undefined') {
          groupedItems[modality].push(item);
        }
      });

      return groupedItems;
    }
    return [];
  }, [providers, sort]);

  return (
    <LandingSection
      icon={IconImg}
      title='Audit'
      linkDetail='/'
      isLoading={isLoading}
      topRight={
        <StyledBox>
          <MultipleSelectDropdown name='auditSort' label='Sort by' control={control} options={sortOptions} multiple={false} />
        </StyledBox>
      }
    >
      <div className='flex flex-col gap-24'>
        {['AIR', 'CT', 'ROAD', 'SEA', 'COURIER'].map((modality) => {
          if (listProviders[modality] && listProviders[modality]?.length > 0) {
            return (
              <LandingAuditProviders
                key={`providers-${modality}`}
                modality={modality as ServiceProviderDetailsDto['modality']}
                providers={listProviders[modality]}
              />
            );
          }
          return null;
        })}
      </div>
    </LandingSection>
  );
};
