import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  InvoiceDto,
  CreditNoteDto,
  InvoiceUploadSignedUrlRequestDto,
  EntityActivityLogDto,
  KeyMetricsResponseDto,
  DomainMetaDataDto,
  ResponseDtoListCurrencyEnum,
  InvoiceCourierShipmentDetailDto,
  CourierInvoiceDisputeDto,
  InvoiceSummaryDto,
  InvoiceAttachmentUploadSignedUrlRequestDto,
  InvoiceFileProcessingStatusDto,
  ShipmentInActionDetailDto,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { ITableData, ITableParams } from 'src/use-cases/dashboard/useDataTable';
import { CacheKeys, cacheKeysUtil } from 'src/utils/cacheKeysUtil';
import { invoiceSearchResponseToTableData, tableFiltersToInvoiceDashboardFilters, tableParamsToInvoiceSearchRequest } from 'src/utils/invoiceMapper';
import { useInvoiceContext } from 'src/presentations/components/organisms/actionDetails/InvoiceContextProvider';
import { useParams } from 'react-router-dom';

export const useInvoiceList = (tableParams: ITableParams = {}): UseQueryResult<ITableData> =>
  useQuery(
    cacheKeysUtil.invoices(tableParamsToInvoiceSearchRequest(tableParams)),
    async (): Promise<ITableData> => {
      const apiRequest = tableParamsToInvoiceSearchRequest(tableParams);
      const apiResponse = await tFinancialService.api.getInvoiceList(apiRequest);
      return invoiceSearchResponseToTableData(apiResponse.data);
    },
    { enabled: !!tableParams?.filters?.currencyReference }
  );

export const useInvoiceStats = (invoiceNumber: string, invoiceDashboardFilters: Record<string, string> = {}): UseQueryResult<KeyMetricsResponseDto> => {
  const filter = { ...tableFiltersToInvoiceDashboardFilters(invoiceDashboardFilters), invoiceNumber };
  return useQuery(
    cacheKeysUtil.invoiceStats(filter),
    async (): Promise<KeyMetricsResponseDto> => (await tFinancialService.api.getKeyMetricsWithFilter('DASHBOARD', filter)).data.data,
    { enabled: !!filter.currencyReference }
  );
};

/**
 * This is for non COURIER INVOICE
 * @param invoiceId
 * @param invoiceSummary
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useInvoiceDetail = (invoiceId: string, invoiceSummary?: InvoiceSummaryDto, _isProcurement?: boolean): UseQueryResult<Record<string, any>> =>
  useQuery(
    cacheKeysUtil.actionDetails(invoiceId),
    async (): Promise<Record<string, any>> =>
      // use get shipment instead of invoice detail
      // const response = await tFinancialService.api.getInvoiceDetail(invoiceId);
      // const { data } = response;
      // data.data.deviations = _.orderBy(data.data.deviations, ({ id }) => id);
      null,
    {
      enabled: !!invoiceSummary && invoiceSummary.modality !== 'COURIER',
    }
  );

export const useSimpleInvoiceDetail = (invoiceId: string): UseQueryResult<InvoiceDto> =>
  useQuery(
    cacheKeysUtil.actionDetails(invoiceId),
    async (): Promise<InvoiceDto> =>
      // use get shipment instead of invoice detail
      // const response = await tFinancialService.api.getInvoiceDetail(invoiceId);
      // const { data } = response;
      null,
    {
      enabled: !!invoiceId,
    }
  );

export const useInvoiceDetailFromParams = () => {
  const { id } = useParams();
  return useInvoiceDetail(id);
};

export const useInvoiceSummaryFromParams = () => {
  const { id } = useParams();
  return useInvoiceSummary(id);
};

interface IPerformDeviationWorkflowActionArgs {
  invoiceId: string;
  reason?: string;
  deviationWorkflowAction:
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'SEND_TO_OPERATION'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
}

export interface IPerformShipmentWorkflowActionArgs {
  shipmentInvoiceId: string;
  invoiceId: string;
  reason?: string;
  deviationWorkflowAction:
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'SEND_TO_OPERATION'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
}

export const usePerformDeviationWorkflowAction = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ invoiceId, deviationWorkflowAction, reason }: IPerformDeviationWorkflowActionArgs) => {
      const apiData = reason ? { reason } : {};
      return tFinancialService.api.performDeviationWorkflowAction(invoiceId, deviationWorkflowAction, apiData);
    },
    {
      onSuccess: async (_, args) => {
        await Promise.all([
          queryClient.invalidateQueries(cacheKeysUtil.actionDetails(args.invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.invoiceSummary(args.invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.userResponse(args.invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.approvalUserResponse(args.invoiceId)),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.invoices],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.invoiceStats],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.serviceProvider],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.getActivityLogs],
          }),
          queryClient.invalidateQueries(cacheKeysUtil.getVersion(args.invoiceId)),
        ]);
      },
    }
  );
};

export const usePerformShipmentWorkflowAction = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ shipmentInvoiceId, deviationWorkflowAction, reason }: IPerformShipmentWorkflowActionArgs) => {
      const apiData = reason ? { reason } : {};
      return tFinancialService.api.performDeviationWorkflowActionOnInvoiceShipment(shipmentInvoiceId, deviationWorkflowAction, apiData);
    },
    {
      onSuccess: async (_, args) => {
        await Promise.all([
          // queryClient.invalidateQueries(cacheKeysUtil.getShipment(args.shipmentInvoiceId)),
          // queryClient.invalidateQueries(cacheKeysUtil.invoiceSummary(args.invoiceId)),
          // queryClient.invalidateQueries(cacheKeysUtil.userResponse(args.invoiceId)),
          // queryClient.invalidateQueries(cacheKeysUtil.approvalUserResponse(args.invoiceId)),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.getShipment],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.invoiceSummary],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.approvalUserResponse],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.invoices],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.invoiceStats],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.serviceProvider],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.getActivityLogs],
          }),
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.activeNoContractDraft],
          }),
          queryClient.invalidateQueries(cacheKeysUtil.getVersion(args.invoiceId)),
        ]);
      },
    }
  );
};

// TODO: group useGetFileUrl, useGetOriginalRateCard, useGetFileAttachmentUrl, useGetInvoiceReport to useInvoiceFile if possible
//  useInvoiceFile(invoiceId: string, type: 'originalRateCard' | 'attachment' | 'report' | 'file'): UseQueryResult<string>
export const useGetFileUrl = (invoiceId: string): UseQueryResult<string> =>
  useQuery(cacheKeysUtil.getFileUrl(invoiceId), async (): Promise<string> => (await tFinancialService.api.getFileUrl(invoiceId)).data.data, {
    enabled: false,
  });

export const useGetConfiguredRateCard = (shipmentInvoiceId: string): UseQueryResult<string> =>
  useQuery(
    cacheKeysUtil.getInvoiceConfiguredRateCard(shipmentInvoiceId),
    async (): Promise<void> => (await tFinancialService.api.getConfiguredRateCard(shipmentInvoiceId, { format: 'blob' })).data,
    {
      enabled: false,
    }
  );

export const useGetFileAttachmentUrl = (invoiceId: string, attachmentId: string): UseQueryResult<string> =>
  useQuery(
    cacheKeysUtil.getFileUrl(invoiceId),
    async (): Promise<string> => (await tFinancialService.api.getFileUrlAttachment(invoiceId, attachmentId)).data.data,
    {
      enabled: false,
    }
  );

export const useGetInvoiceReport = (invoiceId: string): UseQueryResult<string> =>
  useQuery(
    cacheKeysUtil.getInvoiceReport(invoiceId),
    async (): Promise<void> => (await tFinancialService.api.generateInvoiceReport(invoiceId, { format: 'blob' })).data,
    {
      enabled: false,
    }
  );

interface IUploadCreditNoteProps {
  invoiceId: string;
  creditNoteFileUrl: string;
}

interface IGetUploadCreditNoteURLArgs {
  disputeId: string;
  fileName: string;
}

export const useGetUploadCreditNoteURL = () =>
  useMutation(
    async ({ disputeId, fileName }: IGetUploadCreditNoteURLArgs) => (await tFinancialService.api.getUploadCreditNoteUrl1(disputeId, fileName)).data.data
  );

export const useGetCreditNoteFileURL = (disputeId: string): UseQueryResult<string> =>
  useQuery(
    cacheKeysUtil.getCreditNoteFileUrl(disputeId),
    async (): Promise<string> => (await tFinancialService.api.getCreditNoteFileUrl(disputeId)).data.data,
    {
      enabled: false,
    }
  );

export const useUpdateCreditNote = () => {
  const queryClient = useQueryClient();
  const { invoiceId } = useInvoiceContext();

  return useMutation(
    async ({ invoiceId, creditNoteFileUrl }: IUploadCreditNoteProps) => tFinancialService.api.getUploadCreditNoteUrl(invoiceId, { creditNoteFileUrl }),
    {
      onSuccess: async (_, args) => {
        await Promise.all([
          queryClient.invalidateQueries(cacheKeysUtil.actionDetails(invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.getCreditNoteFileUrl(args.invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.getVersion(invoiceId)),
        ]);
      },
    }
  );
};

export const useDeleteCreditNote = () => {
  const queryClient = useQueryClient();
  const { invoiceId } = useInvoiceContext();

  return useMutation(async (invoiceId: string) => tFinancialService.api.deleteCreditNotes(invoiceId), {
    onSuccess: async (_, args) => {
      await Promise.all([
        queryClient.invalidateQueries(cacheKeysUtil.actionDetails(invoiceId)),
        queryClient.invalidateQueries(cacheKeysUtil.getCreditNoteFileUrl(args)),
        queryClient.invalidateQueries(cacheKeysUtil.getVersion(invoiceId)),
      ]);
    },
  });
};

interface IUpdateDisputeCreditNoteProps {
  disputeId: string;
  creditNoteData: CreditNoteDto;
}

export const useGetDisputeCreditNote = (disputeId: string, enabled = true): UseQueryResult<CreditNoteDto> =>
  useQuery(
    cacheKeysUtil.getDisputeCreditNote(disputeId),
    async (): Promise<CreditNoteDto> => (await tFinancialService.api.getInvoiceDisputeCreditNote(disputeId)).data.data,
    {
      enabled,
    }
  );

export const useUpdateDisputeCreditNote = () => {
  const queryClient = useQueryClient();
  const { invoiceId } = useInvoiceContext();

  return useMutation(
    async ({ disputeId, creditNoteData }: IUpdateDisputeCreditNoteProps) => tFinancialService.api.updateInvoiceDisputeCreditNote(disputeId, creditNoteData),
    {
      onSuccess: async (_, args) => {
        await Promise.all([
          queryClient.invalidateQueries(cacheKeysUtil.actionDetails(invoiceId)),
          queryClient.invalidateQueries(cacheKeysUtil.getDisputeCreditNote(args.disputeId)),
          queryClient.invalidateQueries(cacheKeysUtil.getVersion(invoiceId)),
        ]);
      },
    }
  );
};

export const useUploadInvoice = () =>
  useMutation(async (data: InvoiceUploadSignedUrlRequestDto) => {
    const presignedUrls = (await tFinancialService.api.getInvoicePreSignedUrLs(data)).data.data;
    return presignedUrls;
  });

export const useInvoiceFileProcessing = (batchId: string, enabled: boolean) =>
  useQuery(
    cacheKeysUtil.getInvoiceFileProcessing(batchId),
    async (): Promise<InvoiceFileProcessingStatusDto[]> => (await tFinancialService.api.getListInvoiceFileProcessing({ batchId })).data.data,
    {
      enabled: !!enabled,
      cacheTime: 0,
      keepPreviousData: false,
      refetchInterval: 3000,
    }
  );

export const useGetActivityLogs = (invoiceId: string): UseQueryResult<EntityActivityLogDto[]> =>
  useQuery(
    cacheKeysUtil.getActivityLogs(invoiceId),
    async (): Promise<EntityActivityLogDto[]> => (await tFinancialService.api.getInvoiceActivityLogs(invoiceId)).data.data,
    {
      cacheTime: 0,
      keepPreviousData: false,
    }
  );

export const useGetDomainMetaData = (): UseQueryResult<DomainMetaDataDto> =>
  useQuery(cacheKeysUtil.getDomainMetaData(), async (): Promise<DomainMetaDataDto> => (await tFinancialService.api.getDomainMetaData()).data.data, {
    cacheTime: 0,
    keepPreviousData: false,
  });

export const useGetCurrencies = (useType: 'DASHBOARD' | 'REPORT'): UseQueryResult<ResponseDtoListCurrencyEnum> =>
  useQuery(cacheKeysUtil.getCurrencies(), async (): Promise<ResponseDtoListCurrencyEnum> => (await tFinancialService.api.getCurrencies(useType)).data, {
    cacheTime: 0,
    keepPreviousData: false,
  });

export const useInvoiceSummary = (invoiceId: string) =>
  useQuery(cacheKeysUtil.invoiceSummary(invoiceId), async () => (await tFinancialService.api.getInvoiceSummary(invoiceId)).data.data);

export const useApprovalQuestionnaire = (shipmentInvoiceId: string, enabled: boolean = false) =>
  useQuery(
    cacheKeysUtil.approvalQuestionnaire(shipmentInvoiceId),
    async () => (await tFinancialService.api.getApprovalQuestionnaire(shipmentInvoiceId)).data.data,
    {
      enabled,
    }
  );

export const useInvoiceCourierShipmentDetail = (shipmentId: string, enabled: boolean = false): UseQueryResult<InvoiceCourierShipmentDetailDto> =>
  useQuery(
    cacheKeysUtil.getShipmentDetail(shipmentId),
    async (): Promise<InvoiceCourierShipmentDetailDto> => (await tFinancialService.api.getInvoiceCourierShipmentDetail(shipmentId)).data.data,
    {
      enabled,
    }
  );

// export const useInvoiceCourierShipmentDetail = (shipmentId: string, enabled: boolean = false): UseQueryResult<InvoiceCourierShipmentDetailDto> =>
//   useQuery(
//     cacheKeysUtil.getShipmentDetail(shipmentId),
//     async (): Promise<InvoiceCourierShipmentDetailDto> => (mockInvoiceCourierShipment),
//     {
//       enabled,
//     }
//   );

export const useCourierInvoiceDisputeDetail = (invoiceDisputeId: string, enabled: boolean = true): UseQueryResult<CourierInvoiceDisputeDto> =>
  useQuery(
    cacheKeysUtil.courierInvoiceDispute(invoiceDisputeId),
    async (): Promise<CourierInvoiceDisputeDto> => (await tFinancialService.api.getCourierInvoiceDisputeDetail(invoiceDisputeId)).data.data,
    {
      enabled: !!invoiceDisputeId && enabled,
    }
  );

// export const useCourierInvoiceDisputeDetail = (invoiceDisputeId: string, enabled: boolean = true): UseQueryResult<CourierInvoiceDisputeDto> =>
//   useQuery(
//     cacheKeysUtil.courierInvoiceDispute(invoiceDisputeId),
//     async (): Promise<CourierInvoiceDisputeDto> => (mockCourierInvoiceDispute),
//     {
//       enabled,
//     }
//   );

export const useGetVersion = (invoiceId: string, enabled: boolean = false): UseQueryResult<string> =>
  useQuery(cacheKeysUtil.getVersion(invoiceId), async (): Promise<string> => (await tFinancialService.api.getVersion(invoiceId)).data.data, {
    enabled,
    cacheTime: 0,
    keepPreviousData: false,
  });

export const useUploadApprovalDocument = () =>
  useMutation(async (data: InvoiceAttachmentUploadSignedUrlRequestDto) => (await tFinancialService.api.getAttachmentPreSignedUrLs(data)).data.data);

export const useGetShipment = (shipmentInvoiceId?: string): UseQueryResult<ShipmentInActionDetailDto> =>
  useQuery(
    cacheKeysUtil.getShipment(shipmentInvoiceId),
    async (): Promise<ShipmentInActionDetailDto> => (await tFinancialService.api.getShipment(shipmentInvoiceId)).data.data,
    {
      enabled: !!shipmentInvoiceId,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );
