/**
 * Template Root
 */
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PageError from 'src/containers/PageError';
import { UploadInvoice } from '../molecules/uploadInvoice/Upload';

import { ToastResult } from '../molecules/toast/ToastResult';
import { ModalContact } from '../molecules/contact/ModalContact';

const TemplateRoot: React.FC = () => (
  <ErrorBoundary fallback={<PageError />}>
    <Outlet />
    <UploadInvoice />
    <ToastResult />
    <ModalContact />
  </ErrorBoundary>
);

export default TemplateRoot;
