import { ReactNode } from 'react';
import { ServiceProviderDetailsDto } from 'src/generated/services/TFinancialApi';

export enum LandingSectionName {
  ALL = 'ALL',
  AUDIT = 'AUDIT',
  ANALYTIC = 'ANALYTIC',
  PERFORMANCE = 'PERFORMANCE',
}

export interface ISearchFormValues {
  keyword: string;
}

export interface ILandingSectionProps {
  title: string;
  icon: string;
  linkDetail: string;
  topRight?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
}

export interface ILandingMenuProps {
  selected: string;
  auditTotal: number;
  analyticTotal: number;
  performanceTotal: number;
  onChangeMenu(name: string): void;
}

export interface IServiceList {
  AIR: ServiceProviderDetailsDto[];
  ROAD: ServiceProviderDetailsDto[];
  SEA: ServiceProviderDetailsDto[];
  CT: ServiceProviderDetailsDto[];
  COURIER: ServiceProviderDetailsDto[];
}

export interface IAuditProps {
  providers: ServiceProviderDetailsDto[];
  isLoading?: boolean;
}

export interface IAuditProvidersProps {
  providers: ServiceProviderDetailsDto[];
  modality: ServiceProviderDetailsDto['modality'];
}

export interface ILandingTopWidgetProps {
  title?: string;
  children?: ReactNode;
  hideNotification?: boolean;
}
